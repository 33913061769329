import { Component, OnInit } from '@angular/core';
import { SharedService } from '@app/@shared/services/shared-service/shared.service';

@Component({
  selector: 'app-faq-help',
  templateUrl: './faq-help.component.html',
  styleUrls: ['./faq-help.component.scss']
})
export class FaqHelpComponent implements OnInit {

  faqActiveTopic = '';
  faqIndex = 0;
  showAddIcon = false;
  faqTopics = [
    {
      name: "FAQ_CONTENT.faq_topic_1",
      icon: "info",
    },
    {
      name: "FAQ_CONTENT.faq_topic_2",
      icon: "format_list_bulleted",
    },
    {
      name: "FAQ_CONTENT.faq_topic_3",
      icon: "lock",
    }, {
      name: "FAQ_CONTENT.faq_topic_4",
      icon: "wallet",
    },
    {
      name: "FAQ_CONTENT.faq_topic_5",
      icon: "payment",
    },
    {
      name: "FAQ_CONTENT.faq_topic_6",
      icon: "workspace_premium",
    }
  ];
  constructor(private sharedServ: SharedService) {
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.sharedServ.setSharedLogoUrl('assets/icons/logo/Cellpic_logo_blue_1x.svg');
    this.faqActiveTopic = "FAQ_CONTENT.faq_topic_1";
  }

  selectTopic(topic: string) {
    this.faqIndex = 0;
    this.faqActiveTopic = topic;
  }

  onSlide(event: any, topic: string) {
    console.log(event, topic);
    this.faqIndex = 0;
    this.faqActiveTopic = topic;
  }

}

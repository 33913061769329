<div class="text-center position-relative me-4">
    <img [src]="'/assets/icons/footer-icon.png'" alt="icon" class="text-center footer-icon" width="38" height="14">
</div>
<footer class="bg-dark text-light  d-none d-sm-none d-md-none d-lg-block">
    <div class="footer-container mx-auto">
        <div class="py-5">
            <div class="row footer-links justify-content-between">
                <div class="col-lg-auto">
                    <h5>{{"FOOTER_CONTENT.cawaena" | translate}}</h5>
                    <ul class="list-unstyled cursor-pointer">
                        <li routerLink="./mission" (click)="scrollToTop()">{{"FOOTER_CONTENT.mission" | translate}}</li>
                        <li routerLink="./good-reasons" (click)="scrollToTop()">{{"FOOTER_CONTENT.ten_good_reasons" |
                            translate}}</li>
                        <li routerLink="./download" (click)="scrollToTop()">{{"FOOTER_CONTENT.get_cawaena" | translate}}
                        </li>
                    </ul>
                </div>
                <div class="col-lg-auto">
                    <h5>{{"FOOTER_CONTENT.support" | translate}}</h5>
                    <ul class="list-unstyled cursor-pointer">
                        <li routerLink="./faq-help" (click)="scrollToTop()">{{"FOOTER_CONTENT.faq" | translate}}</li>
                        <li routerLink="./licensing" (click)="scrollToTop()">{{"FOOTER_CONTENT.licensing" | translate}}
                        </li>
                        <li routerLink="./community-guidelines" (click)="scrollToTop()">
                            {{"FOOTER_CONTENT.community_guidelines" | translate}}</li>
                    </ul>
                </div>
                <!-- <div class="col-lg-auto cursor-pointer">
                    <h5>Social</h5>
                    <ul class="list-unstyled">
                        <li>Facebook</li>
                        <li>Instagram</li>
                    </ul>
                </div> -->
                <div class="col-lg-auto">
                    <h5>{{"FOOTER_CONTENT.legal" | translate}}</h5>
                    <ul class="list-unstyled cursor-pointer">
                        <li routerLink="./privacy" (click)="scrollToTop()">{{"FOOTER_CONTENT.privacy" | translate}}</li>
                        <li routerLink="./imprint" (click)="scrollToTop()">{{"FOOTER_CONTENT.imprint" | translate}}</li>
                        <li routerLink="./terms-of-use" (click)="scrollToTop()">{{"FOOTER_CONTENT.terms_of_use" |
                            translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="text-center py-4 footer-icons cursor-pointer">
            <a href="https://play.google.com/store/apps/details?id=com.etogruppe.cawaena&hl=en&gl=US"><img
                    [src]="'/assets/icons/play_store.png'" class="me-2" alt="Play store"></a>
            <a href="https://apps.apple.com/de/app/cellpic/id6443486988?l=en-GB"><img
                    [src]="'/assets/icons/app_store.png'" class="ms-2" alt="App store"></a>
        </div>
        <div class="logo-container row mx-0 text-center">
            <a class="navbar-brand eto-logo mt-4" routerLink="/"><img [src]="'/assets/icons/logo/Cellpic_logo_white_1x.svg'" alt="ETO"
                    class="img-fluid"></a>
            <small>Designed in Germany</small>
            <p class="mt-3">2023 © CellPic</p>
        </div>
    </div>
</footer>


<!-- MObile view -->

<footer class="d-block d-sm-block d-md-block d-lg-none text-white">
    <div class="container pt-4 px-0 mb-footer-links">
        <div class="row justify-content-evenly mx-0 my-3">
            <div class="col-auto">
                <div class="mb-4">
                    <h5>{{"FOOTER_CONTENT.cawaena" | translate}}</h5>
                    <ul class="list-unstyled cursor-pointer">
                        <li routerLink="./mission" (click)="scrollToTop()">{{"FOOTER_CONTENT.mission" | translate}}</li>
                        <li routerLink="./good-reasons" (click)="scrollToTop()">{{"FOOTER_CONTENT.ten_good_reasons" |
                            translate}}</li>
                        <li routerLink="./download" (click)="scrollToTop()">{{"FOOTER_CONTENT.get_cawaena" | translate}}
                        </li>
                    </ul>
                </div>
                <div class="mb-4">
                    <h5>{{"FOOTER_CONTENT.support" | translate}}</h5>
                    <ul class="list-unstyled cursor-pointer">
                        <li routerLink="./faq-help" (click)="scrollToTop()">{{"FOOTER_CONTENT.faq" | translate}}</li>
                        <li routerLink="./licensing" (click)="scrollToTop()">{{"FOOTER_CONTENT.licensing" | translate}}
                        </li>
                        <li routerLink="./community-guidelines" (click)="scrollToTop()">
                            {{"FOOTER_CONTENT.community_guidelines" | translate}}</li>
                    </ul>
                </div>
            </div>
            <div class="col-auto">
                <div class="mb-4">
                    <h5>{{"FOOTER_CONTENT.legal" | translate}}</h5>
                    <ul class="list-unstyled cursor-pointer">
                        <li routerLink="./privacy" (click)="scrollToTop()">{{"FOOTER_CONTENT.privacy" | translate}}</li>
                        <li routerLink="./imprint" (click)="scrollToTop()">{{"FOOTER_CONTENT.imprint" | translate}}</li>
                        <li routerLink="./terms-of-use" (click)="scrollToTop()">{{"FOOTER_CONTENT.terms_of_use" |
                            translate}}</li>
                    </ul>
                </div>
                <!-- <div class="mb-4">
                    <h5>Social</h5>
                    <ul class="list-unstyled">
                        <li>Facebook</li>
                        <li>Instagram</li>
                    </ul>
                </div> -->
            </div>
        </div>

        <div class="mt-4">
            <div class="text-center py-4 footer-icons w-100 cursor-pointer">
                <a href="https://play.google.com/store/apps/details?id=com.etogruppe.cawaena&hl=en&gl=US"><img
                        [src]="'/assets/icons/play_store.png'" class="me-2" alt="Play store"></a>
                <a href="https://apps.apple.com/de/app/cellpic/id6443486988?l=en-GB"><img
                        [src]="'/assets/icons/app_store.png'" class="ms-2" alt="App store"></a>
            </div>
            <div class="mb-logo-container text-center mt-3">
                <a class="navbar-brand eto-logo mt-4" routerLink="/" (click)="scrollToTop()"><img
                        [src]="'/assets/icons/logo/Cellpic_logo_white_1x.svg'" alt="ETO" width="154px" height="31px"
                        class="img-fluid"></a>
                <small>Designed in Germany</small>
                <p class="mt-3">2023 © CellPic</p>
            </div>
        </div>
    </div>
</footer>
import { Component, OnInit } from '@angular/core';
import { SharedService } from '@app/@shared/services/shared-service/shared.service';

@Component({
  selector: 'app-licensing',
  templateUrl: './licensing.component.html',
  styleUrls: ['./licensing.component.scss']
})
export class LicensingComponent implements OnInit {

  constructor(private sharedServ: SharedService){
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.sharedServ.setSharedLogoUrl('assets/icons/logo/Cellpic_logo_blue_1x.svg');
  }
}
